<template>
  <div class="gift-receive-container">
    <!-- 领取藏品轮播图 -->
    <div class="gift-list">
      <img
        @click="preGift"
        class="gift-receive-left"
        src="../assets/icon/giftLogin/left-icon.png"
      />
      <img
        @click="nextGift"
        class="gift-receive-right"
        src="../assets/icon/giftLogin/right-icon.png"
      />
      <van-swipe
        ref="mySwipe"
        class="my-swipe"
        @change="onChange"
      >
        <van-swipe-item
          v-for="(item,index) in giftList"
          :key="index"
        >
          <img :src="item.skuImg" />
        </van-swipe-item>
      </van-swipe>

    </div>
    <!-- 藏品信息 -->
    <div class="collection-info">
      <img
        class="collection-info-bg"
        src="../assets/icon/giftLogin/collection-info-bg.png"
      />
      <div class="collection-info-container">
        <p class="collection-info-name">{{ giftList[currentIndex].skuName }}</p>
        <p class="collection-info-tip">数量：{{ giftList[currentIndex].skuNum }} 份</p>
      </div>

    </div>
    <!-- 藏品发放信息 -->
    <div class="collection-receive-info">
      <img
        class="collection-receive-bg"
        src="../assets/icon/giftLogin/collection-receive-bg.png"
      />
      <div class="collection-receive-info-container col-center">
        <p class="collection-receive-info-title">数字藏品领取成功</p>
        <p class="collection-receive-info-account">已发放至：{{ phoneNum }}</p>
        <p class="collection-receive-info-tip">藏品上链中，稍后可在账户中查看</p>
        <div
          @click="goMyCollection"
          class="gift-login-button"
        >
          <img
            class="gift-login-bg"
            native-type="submit"
            src="../assets/icon/giftLogin/button-bg.png"
          />
          <img
            class="gift-login-tip"
            src="../assets/icon/giftLogin/gift-detail-button-tip.png"
          />
        </div>
      </div>

    </div>
    <div class="user-agreement">
      <p class="user-agreement-title">智链2.0提供区块链技术支持</p>
      <p class="user-agreement-tip">Copyright © GenimousChain Ltd.All rights reserved</p>
    </div>
  </div>
</template>
<script>
import api from '../api/index-client'
import { Swipe,SwipeItem } from "vant";

export default {
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  data () {
    return {
      giftList: [
        {
          skuName: ''
        }
      ],
      phoneNum: '',
      currentIndex: 0
    }
  },
  mounted () {
    this.getGiftList()
    this.$store.commit('HIDE_APPLOADING')
  },
  methods: {
    onChange (index) {
      this.currentIndex = index
    },
    // 上一张
    preGift () {
      this.$refs.mySwipe.prev()
    },
    // 下一张
    nextGift () {
      this.$refs.mySwipe.next()
    },
    // 获取礼物列表
    getGiftList () {
      api
        .get('grantAssets?configId=' + this.$route.query.id)
        .then(result => {
          if (result.data.success) {
            this.phoneNum = result.data.data.phone
            this.giftList = result.data.data.list
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    goMyCollection () {
      this.$router.push('/myCollection')
    }
  },
}
</script>
<style lang="less" scoped>
.gift-receive-container {
  width: 100%;
  height: 100vh;
  min-height: 660px;
  position: relative;
  background-image: url('../assets/icon/giftLogin/gift-receive-bg.png');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  background-position: bottom;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;

  .gift-list {
    position: relative;
    .gift-receive-left {
      position: absolute;
      width: 34px;
      top: 107px;
      left: 20px;
      z-index: 200;
    }
    .gift-receive-right {
      position: absolute;
      width: 34px;
      top: 107px;
      right: 20px;
      z-index: 200;
    }
  }
  .my-swipe {
    width: 100vw;
    z-index: 199;
  }
  /deep/.van-swipe__indicators {
    display: none !important;
  }
  .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    width: 100%;
  }
  .van-swipe {
    height: 270px;
    img {
      margin-top: 33px;
      width: 170px;
      height: auto;
    }
  }
  .collection-info {
    margin-top: 40px;
    position: relative;
    .collection-info-bg {
      width: 74.6vw;
      min-width: 290px;
      height: auto;
    }
    .collection-info-container {
      position: absolute;
      top: 15px;
      left: 15%;
      width: 70%;
    }
    .collection-info-name {
      width: 100%;
      text-align: left;
      font-size: 13px;
      font-family: Alibaba PuHuiTi 2;
      font-weight: 300;
      color: #ffffff;
    }
    .collection-info-tip {
      width: 100%;
      text-align: left;
      font-size: 13px;
      font-family: Alibaba PuHuiTi 2;
      font-weight: 300;
      color: #ffffff;
    }
  }

  .user-agreement {
    margin-top: 20px;
    .user-agreement-title,
    .user-agreement-tip {
      width: 100%;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: #a09f96;
      line-height: 17px;
    }

    .user-agreement-tip {
      font-size: 0.75rem;
      font-weight: 400;
      color: #a09f96;
      line-height: 1.0625rem;
      margin: 8px 0 25px 0;
    }
  }
  .collection-receive-info {
    position: relative;
    .collection-receive-bg {
      width: 74.6vw;
      min-width: 290px;
      height: auto;
    }
    .collection-receive-info-container {
      position: absolute;
      top: 30px;
      width: 100%;
    }
    .collection-receive-info-title {
      width: 100%;
      text-align: center;
      font-size: 15px;
      font-family: Alibaba PuHuiTi 2;
      font-weight: 300;
      color: #ffffff;
    }
    .collection-receive-info-account {
      margin-top: 5px;
      width: 100%;
      text-align: center;
      font-size: 13px;
      font-family: Alibaba PuHuiTi 2;
      font-weight: 300;
      color: #ffffff;
    }
    .collection-receive-info-tip {
      margin-top: 5px;
      width: 100%;
      text-align: center;
      font-size: 12px;
      font-family: Alibaba PuHuiTi 2;
      font-weight: 300;
      color: #939393;
    }
  }
  .gift-login-button {
    margin-top: 35px;
    position: relative;
    .gift-login-bg {
      margin-top: 20px;
      height: auto;
      width: 70vw;
    }
    .gift-login-tip {
      position: absolute;
      top: 45%;
      margin-top: 0px;
      left: 23vw;
      width: 24vw;
      height: auto;
    }
  }
}
</style>